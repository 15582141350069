import React from 'react';
import { graphql } from 'gatsby';
import Seo from '../../components/layout/seo';

// Layout
import Layout from '../../components/layout/layout';

// Fields
import Content from '../../components/fields/Content';

const NodeHomepageTemplate = ({ data }) => {
	const { title, summary } = data.nodeHomepage;
	const { content } = data.nodeHomepage.r;

	return (
		<Layout>
			<Seo title='Home' keywords={[`gatsby`, `application`, `react`]} />
			<Content content={content} />
		</Layout>
	);
};

export default NodeHomepageTemplate;

export const query = graphql`
	query homepageTemplate($slug: Int) {
		nodeHomepage(drupal_internal__nid: { eq: $slug }) {
			...nodeHomepageFragment
		}
	}
`;
